import './styles/ebook-overview.scss';
type EbookOverviewProps = {
  author: Author,
  className: string,
  excerpt: {
    text: string
  }
}

type Author = {
  author_image: {
    source_url: string
  }
  author_information: string,
  author_name: string,
  author_title: string,
  section_title: string
}

const EbookOverview = ({ excerpt, author, className }:EbookOverviewProps) => (
  <section
    className={ `ebook-overview-section section ${className || ''}` }
  >
    <div className="container">
      <div className="columns">
        <div className="column excerpt">
          <div className="excerpt-pages">
            <div
              className="excerpt-text"
              dangerouslySetInnerHTML={ { __html: excerpt.text } }
            />
          </div>
        </div>
        <div className="column author">
          <div className="media">
            <div className="media-wrapper">
              <figure className="media-left">
                <picture className="image">
                  <img src={ author.author_image.source_url } />
                </picture>
              </figure>
              <div className="media-content">
                <div className="content">
                  <h3 className="title">{author.section_title}</h3>
                  <h4 className="author-name">{author.author_name}</h4>
                  <p className="company-title">{author.author_title}</p>
                </div>
              </div>
            </div>

            <p className="copy">{author.author_information}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default EbookOverview;