import Image from 'components/content-blocks/partials/image';
import Wysiwyg from 'components/content-blocks/partials/wysiwyg';
import Vero from 'components/forms/vero';

import './styles/ebook.scss';

type HeroEbookProps = {
  appleBookUrl: string,
  audibleUrl: string,
  backgroundImage: string,
  contentTitle: string,
  copy: string,
  downloadUrl: string,
  submitBtnText: string,
  title?: string,
  wysiwygTitle?: string
}

export const HeroEbook = ({
  title,
  copy,
  backgroundImage,
  downloadUrl,
  contentTitle,
  submitBtnText,
  wysiwygTitle,
  appleBookUrl,
  audibleUrl,
}:HeroEbookProps) => (
  <section
    className="ebook-hero-section section hero"
    data-section="ebook-hero"
  >
    <div className="container">
      <div className="columns is-column-tablet is-flex-mobile hero-columns">
        <div className="column is-11 is-6-desktop has-margin-right-auto has-text-left-desktop vero-column">
          {title && <h1 className="hero-title">{title}</h1>}
          {wysiwygTitle && <h1 className="hero-title" dangerouslySetInnerHTML={ { __html: wysiwygTitle } } />}
          <Wysiwyg content={ copy } />
          <Vero
            formType="contentDownload"
            fullNameEmailGrouped={ true }
            fullNameField
            emailField
            fieldLayout="row"
            contentTitle={ contentTitle }
            downloadUrl={ downloadUrl }
            submitBtnText={ submitBtnText }
            uniqueId="-vero-ebook"
          />
        </div>
        <div className="column ebook-image-column">
          <div className="book-container">
            <Image src={ backgroundImage } />
            {(appleBookUrl || audibleUrl) && (
              <div className="audio-books">
                {appleBookUrl && (
                  <a className="apple-book" href={ appleBookUrl }>
                    <img
                      src="https://marketing-assets.wheniwork-production.com/2021/01/20122942/apple-books.svg"
                      alt="Apple Book Logo"
                    />
                  </a>
                )}
                {audibleUrl && (
                  <a className="audible-book" href={ audibleUrl }>
                    <img
                      src="https://marketing-assets.wheniwork-production.com/2021/01/20122943/audible.svg"
                      alt="Audible Logo"
                    />
                  </a>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </section>
);
