import Layout from 'layouts/default';

import CustomerReviews from 'components/content-blocks/customer-reviews/customer-reviews';
import EbookOverview from 'components/content-blocks/ebook-overview';
import HighlightCards from 'components/content-blocks/highlight';
import Yoast from 'components/globals/yoast';

import './styles/library.scss';
import LibraryHero from './template-const/library-hero';
/*
This is a basic page template. 
In order for this template to work, don't create a file in /page that controls a page in wordpress.
If pages are created in gatsby then it will stop listening to this page template all together.
The following pages use the template:
- library/*
*/

export default function LibraryTemplate({ pageContext }) {
  return (
    <Layout>
      <Yoast { ...pageContext.yoast_head_json } />
      <div className="library-template-container" data-signal={ pageContext.wordpress_id }>
        {pageContext.acf.ebook_hero !== null && (
          <LibraryHero
            libraryHero={ pageContext.acf.ebook_hero }
            downloadContent={ pageContext.acf.download_content }
          />
        )}
        <div className="ebook-overview-container">
          {pageContext.acf.cards_1 !== null && (
            <HighlightCards
              { ...pageContext.acf.cards_1 }
              hasTitle
              hasCopy
              hasShadow
            />
          )}
          {!!pageContext.acf.ebook_overview && (
            <EbookOverview { ...pageContext.acf.ebook_overview } />
          )}
          {!!pageContext.acf.customer_reviews && (
            <CustomerReviews { ...pageContext.acf.customer_reviews } />
          )}
        </div>
      </div>
    </Layout>
  );
}
