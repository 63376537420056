import { HeroEbook } from 'components/hero/ebook';

export default function LibraryHero({ libraryHero, downloadContent }) {
  return (
    <HeroEbook
      backgroundImage={ libraryHero.background_image.source_url }
      title={ libraryHero.title }
      copy={ libraryHero.copy }
      appleBookUrl={ libraryHero.apple_book_link }
      audibleUrl={ libraryHero.audible_link }
      contentTitle={ downloadContent.content_title }
      downloadUrl={ downloadContent.download_url.source_url }
      submitBtnText={ downloadContent.submit_button_text }
      data-section="ebook-hero"
    />
  );
}
